<template>
  <div class="chart">
    <div class="chart-screen">
      <div :class="active=='a'?'active':''" @click.stop="screen('5','a')" >省份</div>
      <div :class="active=='b'?'active':''" @click.stop="screen('1','b')">城市</div>
      <div :class="active=='c'?'active':''" @click.stop="screen('6','c')">县区</div>
      <div :class="active=='d'?'active':''" @click.stop="screen('4','d')">品牌</div>
      <div :class="active=='e'?'active':''" @click.stop="screen('3','e')">商品</div>
    </div>
  <div :class="className" :id="id" :style="{height:height,width:width}" ref="myEchart">
  </div>
  </div>
</template>
<!--<script src="https://cdn.jsdelivr.net/npm/echarts@5.1.0/dist/echarts.min.js"></script>-->
<script>
import echarts from 'echarts';
import { chartData } from "@api/tasting";
export default {
  name: 'charts',
  // externals: {
  //   echarts: 'echarts',
  // },
  components:{
    echarts
  },
  props: {
    className: {
      type: String,
      default: 'yourClassName'
    },
    id: {
      type: String,
      default: 'yourID'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '600px'
    }
  },
  data() {
    return {
      chart: null,
      list: [],
      active:'a',
    }
  },
  mounted() {
    //this.initChart();
    this.screen(5,'a');
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    screen(code,type){
      this.active = type;
      chartData({type:code}).then(res=>{
        //console.log(res);
        //this.height = res.data.num*100+'px';
        this.initChart(res.data);
      })
    },
    initChart(res) {
      //console.log(res.h5);
      
      console.log(this.height);
      this.chart = echarts.init(this.$refs.myEchart);
      // 把配置和数据放这里
      this.chart.setOption({
        dataset: {
          //source: [
              // ['score', '次数', '城市'],
              // [1, 100, 'Matcha Latte'],
              // [57.1, 10, 'Milk Tea'],
              // [74.4, 41, 'Cheese Cocoa'],
              // [50.1, 12, 'Cheese Brownie'],
              // [89.7, 20, 'Matcha Cocoa'],
              // [68.1, 79, 'Tea'],
              // [19.6, 2, 'Orange Juice'],
              // [10.6, 11, 'Lemon Juice'],
              // [32.7, 10, 'Walnut Brownieg'],
              // [32.7, 10, 'Walnut Brownier'],
              // [32.7, 10, 'Walnut Brownieb'],
              // [32.7, 10, 'Walnut Browniea'],
              // [32.7, 10, 'Walnut Browniex'],
          //]
        source: res.h5
    },
    grid: {containLabel: true},
    xAxis: {name: '次数',minInterval: 1},
    yAxis: {name: res.title, type: 'category'},
    //visualMap : {
        //symbolSize:'1000px',
        //orient: 'horizontal',
        //left: 'center',
        //min: 10,
        //max: 100,
        //text: ['High Score', 'Low Score'],
        // Map the score column to color
        //dimension: 0,
        // inRange: {
        //     color: ['#65B581', '#FFCE34', '#FD665F']
        // }
    //},
    series: [
        {
            type: 'bar',
            encode: {
                // Map the "amount" column to X axis.
                x:  res.title,
                // Map the "product" column to Y axis
                y: res.name
            },
            barWidth: 15,
            color: ['#FD665F'],
        }
    ]
      })
    }
  }
}
</script>
<style lang="scss">
  .chart .chart-screen{
      display: flex;
      -webkit-align-items: center;
      align-items: center;
      -webkit-justify-content: center;
      justify-content: center;
      text-align: center;
      -webkit-text-align: center;
      div{
        flex-direction:row;
        justify-content:space-evenly;
        width: 100%;
        margin: 0.2rem;
        padding: 0.05rem 0.1rem;
        border: 2px solid #ccc;
        border-radius:8px;
      }
      div.active{
        background-color: #f00;
        color: #fff;
      }
  }
</style>